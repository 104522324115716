import React from "react"
import styled from "styled-components"

let Element = styled.div`
  width: 100%;
  height: ${ props => props.height }; 
  margin-bottom: ${ props => props.marginBottom ? props.marginBottom : '0' }; 
`

const Spacer = ({ height, marginBottom }) => {
  return(
    <Element
      height={ height }
      marginBottom={ marginBottom }
    />
  )
}
export default Spacer
