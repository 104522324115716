import React from "react"
import widths from "../utils/dimensions"
import styled from "styled-components"
import Img from "gatsby-image"

let Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
let Tile = styled.div`
  width: 100%;
  height: 0;
  padding-top: 85%;
  max-height: 900px;
  background: ${ props => props.bgcolour ? props.bgcolour : 'white' };
  position: relative;
  .gatsby-image-wrapper{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    position: ${ props => props.image ? 'absolute' : 'relative' } !important;
  }
  @media screen and (min-width: 450px){
    padding-top: 56.25%;
  }
  @media screen and (min-width: 850px){
    width: 50%;
    height: 50vh;
    padding: 0;
  } 
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    height: 100vh;
  } 
`
let TileInner = styled.div`
  width: 430px;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50%);
  text-align: left;
  @media screen and (min-width: 850px){
    max-width: 90%;
  } 
`

export const LeadContainer = ({ children }) => {
  return(
    <Container>{ children }</Container>
  )
}

export const LeadTile = ({ bgColour, image, title, text, className }) => {
  return(
    <Tile
      bgcolour={ bgColour }
      className={ className }
      image={ image }
    >
      { image &&

        <Img
          fluid={ image.imageFile.childImageSharp.fluid }
          alt={ image.altText }
        />

      }
      { title &&

        <TileInner>
          <h3 dangerouslySetInnerHTML={{ __html: title }}/>
          <p>{ text }</p>
        </TileInner>

      }
    </Tile>
  )
}