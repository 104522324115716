import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import HeaderImage from "../components/header-image"
import Section from "../components/section"
import Page from "../components/page"
import { LeadContainer, LeadTile } from "../components/lead-tiles"
import { SectionTiles, SectionTile } from "../components/section-tiles"
import colours from "../utils/colours"
import Img from "gatsby-image"
import Spacer from "../components/spacer"
import ImageTiles from "../components/image-tiles"
import styled from "styled-components"
import MoreInfoTiles from "../components/more-info-tiles"

let Awards = styled.div`
  width: 100%;
  p{
    text-align: center;
  }
  .gatsby-image-wrapper{
    max-width: 400px; 
    margin: 40px auto 0 auto;
  }
`

const AboutPage = ({ location }) => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "about") {
                  slug
                  seo {
                      title
                      metaDesc
                  }
                  id
                  aboutPage {
                      title
                      text
                      headerImage {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid(quality: 90) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      leadTitle
                      leadText
                      leadImage {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      tilesTitle
                      tilesText
                      tiles {
                          title
                          text
                          image {
                              sourceUrl
                              altText
                              imageFile {
                                  childImageSharp {
                                      fluid {
                                          ...GatsbyImageSharpFluid_withWebp
                                      }
                                  }
                              }
                          }
                      }
                      differenceTitle
                      differenceText
                      differenceImage1 {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      differenceImage2 {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      differenceImage3 {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      awardsText
                      awardsImage {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)
  let seoData = data.wpgraphql.pageBy.seo
  let pageData = data.wpgraphql.pageBy.aboutPage

  return(
    <Page
      seoTitle={ seoData.title }
      seoDescription={ seoData.metaDesc }
    >
      <HeaderImage
        image={ pageData.headerImage }
      />
      <Section>
        <h1 dangerouslySetInnerHTML={{ __html: pageData.title }}/>
        <p dangerouslySetInnerHTML={{ __html: pageData.text }}/>
      </Section>

      <LeadContainer>
        <LeadTile
          image={ pageData.leadImage }
        />
        <LeadTile
          bgColour={ colours.tileGrey }
          title={ pageData.leadTitle }
          text={ pageData.leadText }
        />
      </LeadContainer>

      <Section
        backGColour={ 'black' }
        colour={ 'white' }
      >
        <h2 dangerouslySetInnerHTML={{ __html: pageData.tilesTitle }}/>
        <p dangerouslySetInnerHTML={{ __html: pageData.tilesText }}/>
        <Spacer height={ '80px' }/>

        { pageData.tiles.map(({ title, text, image }, index ) => {
          return (

            <SectionTiles key={ index }>
              <SectionTile
                image={ image }
              />
              <SectionTile
                title={ title }
                text={ text }
              />
            </SectionTiles>

          )})}

        {/*<Spacer height={ '30px' }/>*/}
      </Section>

      <Section
        backGColour={ colours.highlightGrey }
        colour={ 'black' }
      >
        <h1 dangerouslySetInnerHTML={{ __html: pageData.differenceTitle }}/>
        <p dangerouslySetInnerHTML={{ __html: pageData.differenceText }}/>
        <ImageTiles
          image1={ pageData.differenceImage1 }
          image2={ pageData.differenceImage2 }
          image3={ pageData.differenceImage3 }
        />
      </Section>

      <Section>
        <Awards>
          <p>{ pageData.awardsText }</p>
          <Img
            fluid={ pageData.awardsImage.imageFile.childImageSharp.fluid }
            alt={ pageData.awardsImage.altText }
          />
        </Awards>

      </Section>

      <Section backGColour={ colours.highlightGrey }>
        <MoreInfoTiles
          location={ location }
        />
      </Section>

    </Page>
  )
}
export default AboutPage
