import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

let Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &:nth-of-type(even){
    flex-direction: row-reverse;
  }
  &:last-of-type{
    margin-bottom: -70px;
    @media screen and (min-width: 850px){
      margin-bottom: 0;
    }
  }
`
let Tile = styled.div`
  width: 100%;
  &:nth-of-type(even){
    .tile-inner{
      height: auto;
      padding: 40px 0 70px 0;
      @media screen and (min-width: 850px){
        height: 100%;
      }
    }
  }
  .tile-inner{
    width: 100%;
    height: 0;
    padding-top: 75%; 
    position: relative;
    .gatsby-image-wrapper{
      position: absolute !important;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .tile-content{
       margin-top: -10px;
       h3{
        margin-bottom: 12px;
       }
       @media screen and (min-width: 850px){
          position: absolute;
          width: calc(100% - 80px);
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        } 
    }
    @media screen and (min-width: 500px){
      padding: 56.25% 0 0 0;
      margin-top: 0;
    }
    @media screen and (min-width: 850px){
      padding-top: 100%;
    }
    @media screen and (min-width: 1200px){
      padding-top: 75%;
    }
  }
  @media screen and (min-width: 850px){
    width: 50%;
  } 
`

export const SectionTiles = ({ children }) => {
  return(
    <Container>{ children }</Container>
  )
}

export const SectionTile = ({ image, title, text }) => {
  return(
    <Tile>
      <div className={ 'tile-inner' }>
        { image &&

          <Img
            fluid={ image.imageFile.childImageSharp.fluid }
            alt={ image.altText }
          />

        }
        { title &&

          <div className={ 'tile-content' }>
            <h3 dangerouslySetInnerHTML={{ __html: title }}/>
            <p dangerouslySetInnerHTML={{ __html: text }}/>
          </div>

        }
      </div>
    </Tile>
  )
}